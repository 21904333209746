import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  langs = ['en', 'it'];

  constructor(private translateService: TranslateService) {}

  public ngOnInit(): void {
    let browserlang = this.translateService.getBrowserLang();
    this.setBrowserLanguage(browserlang);
  }


  setBrowserLanguage(langBrw: string) {
    if (this.langs.indexOf(langBrw) > -1) {
      this.translateService.setDefaultLang(langBrw);
    } else {
      this.translateService.setDefaultLang('en');
    }

  }
}
