// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  dom_env: 'jeep',
  dynamikLinkToapp: 'https://jeepcert.page.link/Zi7X',
  apiUrl: '/api',

  sapApikey: '3_u3ddySw4CZSxeUSJGD8TF3zPPx374LPLjpBWZn9zW3N4d0bsSyLURO_wTpDQeF5Q',

  idpBroker: {
    link: 'https://api01-emea-qa.fcagroup.com:44300/emea/sb/IdPBroker/b2c/v1/users',
    user: '6bbffeef-2a12-436e-85ef-b4a1d290c1f2',
    key: 'hW4nT3eY2eS5sC3hQ5tA8oK4vS2qX2wH1hH0wT4lW8kG8iF6aS'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
