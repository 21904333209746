import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MainDialogComponent} from "../_component/main-dialog/main-dialog.component";
import {logger} from "codelyzer/util/logger";

@Injectable({providedIn: 'root'})
export class MyModalService {

  constructor(
    private translate: TranslateService,
    public modalService: NgbModal,
  ) {
  }

  openModal(title,message) {
    message = this.translate.instant(message);
    title = this.translate.instant(title);
    const modalRef = this.modalService.open(MainDialogComponent,{size: 'lg',centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.result.then((result) => {
      if (result) {
        console.log(result);
      }
    }).catch((res) => {
      console.log(res);
    });
  }
}
