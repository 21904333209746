import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MyModalService} from './modal.service';
import {Router} from '@angular/router';
import {Constants} from './Constants';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private modal: MyModalService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(err => {

      /*if(err.status == 400 || err.status == 500){
          this.modal.ERROR(Constants.TITLE_ERROR, '');
      }*/
      const error = err.error.message || err.statusText;
      return throwError(error);
    }));
  }

}
