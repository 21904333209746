import { environment } from 'src/environments/environment';

export class Constants{

    public static TITLE_INFO = 'INFO!';
    public static TITLE_ERROR = 'ERROR!';
    public static TITLE_WARNING = 'WARNING!';
    public static TITLE_SUCCESS = 'SUCCESS!';

    static Messages = class{
        public static RESETPWDKEY_NOTFOUND = 'reset-password.resetkey-notfound';
    }

    static Routing = class{
        public static POLICY = { label: 'Policy', path:"policy", routerLink: ['/policy'] };
        public static ACCOUNT_SUCCESS = { label: 'Account Success', path:"account-success", routerLink: ['/account-success'] };
        public static ACCOUNT_ERROR = { label: 'Account Error', path:"account-activation-error", routerLink: ['/account-activation-error'] };
    }

    static Country = class{

        public static readonly IT = { code : 'IT', langs : ['it']};
        public static readonly GB = { code : 'GB', langs : ['en']};
        public static readonly FR = { code : 'FR', langs : ['fr']};
        public static readonly DE = { code : 'DE', langs : ['de']};
        public static readonly BE = { code : 'BE', langs : ['fr', 'nl']};
        public static readonly CH = { code : 'CH', langs : ['it','de', 'fr']};

    }

    static IdpBroker = class{
        public static queryParam_resetKey = 'pwrt';
    }

    static ScriptStore = class{
        public static Scripts = [
            {name: 'gigya', src: 'https://cdns.eu1.gigya.com/js/gigya.js?apikey='+environment.sapApikey},
        ]

    }


}