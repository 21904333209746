import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './_layout/layout.component';
import { Constants } from './_helper/Constants';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule) },
  { path: '', component: LayoutComponent,
    children: [
      { path: Constants.Routing.POLICY.path, loadChildren: () => import('./_layout/policy/policy.module').then(mod => mod.PolicyModule), canActivate: [] },
      { path: ':country/:ln/watch-video', loadChildren: () => import('./_layout/video/video.module').then(mod => mod.VideoModule), canActivate: [] },
      { path: 'password-success', loadChildren: () => import('./_layout/password-changed/password-changed.module').then(mod => mod.PasswordChangedModule), canActivate: [] },
      { path: ':country/:ln/reset-password', loadChildren: () => import('./_layout/reset-password/reset-password.module').then(mod => mod.ResetPasswordModule), canActivate: [] },
      { path: ':ln/reset-password', loadChildren: () => import('./_layout/reset-password/reset-password.module').then(mod => mod.ResetPasswordModule), canActivate: [] },
      { path: 'reset-password', loadChildren: () => import('./_layout/reset-password/reset-password.module').then(mod => mod.ResetPasswordModule), canActivate: [] },
      { path: Constants.Routing.ACCOUNT_SUCCESS.path, loadChildren: () => import('./_layout/account-success/account-success.module').then(mod => mod.AccountSuccessModule), canActivate: [] },
      { path: Constants.Routing.ACCOUNT_ERROR.path, loadChildren: () => import('./_layout/account-activation-error/account-activation-error.module').then(mod => mod.AccountActivationErrorModule), canActivate: [] },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
